// eslint-disable-next-line @nx/enforce-module-boundaries
import dynamic from 'next/dynamic';

export default {
  // Navigation
  BreadCrumbs: dynamic(() => import('@sitecore/components/content/Breadcrumbs/Breadcrumbs')),
  FooterMenu: dynamic(() => import('@sitecore/components/content/Footer/FooterMenu')),
  Footer: dynamic(() => import('@sitecore/components/content/Footer/Footer')),
  LanguageSelector: dynamic(() => import('@sitecore/components/content/LanguageSelector/LanguageSelector')),
  Logo: dynamic(() => import('@sitecore/components/woonenergie/ImageLink/ImageLink')),
  MainNavigation: dynamic(() => import('@apps/header/shared/multilabel/src/Header')),
  MegaMenu: dynamic(() => import('@sitecore/components/content/MegaMenu/MegaMenu')),
  MegaMenuDivision: dynamic(() => import('@sitecore/components/content/MegaMenuDivision/MegaMenuDivision')),
  MessageBar: dynamic(() => import('@sitecore/components/content/MessageBar/MessageBar')),
  NotificationsBar: dynamic(() => import('@sitecore/components/content/NotificationsBar/NotificationsBar')),
  SocialMediaMenu: dynamic(() => import('@sitecore/components/content/Footer/SocialMediaMenu')),
  SubFooterMenu: dynamic(() => import('@sitecore/components/content/Footer/SubFooterMenu')),

  // Content components
  Accordion: dynamic(() => import('@sitecore/components/content/Accordion/Accordion')),
  ContentCategories: dynamic(() => import('@sitecore/components/content/ContentCategories/ContentCategories')),
  ContactCustomerService: dynamic(
    () => import('@sitecore/components/content/ContactCustomerService/ContactCustomerService'),
  ),
  Hero: dynamic(() => import('@sitecore/components/content/Hero/Hero')),
  HeroCard: dynamic(() => import('@sitecore/components/content/HeroCard/HeroCard')),
  IconList: dynamic(() => import('@sitecore/components/content/IconList/IconList')),
  Image: dynamic(() => import('@sitecore/components/content/Image/Image')),
  LinkCard: dynamic(() => import('@sitecore/components/content/LinkCard/LinkCard')),
  List: dynamic(() => import('@sitecore/components/content/List/List')),
  NotificationBox: dynamic(() => import('@sitecore/components/content/NotificationBox/NotificationBox')),
  Price: dynamic(() => import('@sitecore/components/content/Price/Price')),
  SimpleTable: dynamic(() => import('@sitecore/components/content/SimpleTable/SimpleTable')),
  StoreButtons: dynamic(() => import('@sitecore/components/content/StoreButtons/StoreButtons')),
  TextStreamer: dynamic(() => import('@sitecore/components/content/TextStreamer/TextStreamer')),
  TopTaskCTA: dynamic(() => import('@sitecore/components/content/TopTaskCTA/TopTaskCTA')),
  TopTasks: dynamic(() => import('@sitecore/components/content/TopTasks/TopTasks')),
  UsabillaFeedback: dynamic(() => import('@sitecore/components/system/UsabillaFeedback/UsabillaFeedback')),
  WeDoenHetNuCard: dynamic(() => import('@sitecore/components/content/Road2Zero/WeDoenHetNuCard/WeDoenHetNuCard')),
  AddressFinder: dynamic(() => import('@sitecore/components/content/AddressFinder/AddressFinder')),
  AffiliateCalculationTool: dynamic(() => import('@apps/flows/nl/eneco/energyFlow/src/CalculationTool')),
  StepExplainer: dynamic(() => import('@sitecore/components/content/StepExplainerVertical/StepExplainerVertical')),
  ShareOnSocials: dynamic(() => import('@sitecore/components/content/ShareOnSocials/ShareOnSocials')),
  ButtonLink: dynamic(() => import('@sitecore/components/content/ButtonLink/ButtonLink')),
  ButtonLineUp: dynamic(() => import('@sitecore/components/content/ButtonLineUp/ButtonLineUp')),

  // Other
  CookieWall: dynamic(() => import('@apps/cookiewall/nl/multilabel/src/CookieWall')),
  CustomerReview: dynamic(() => import('@apps/customerreview/shared/multilabel/src/CustomerReview')),
  ErrorPage: dynamic(() => import('@sitecore/components/content/ErrorPage/ErrorPage')),
  GTM: dynamic(() => import('@apps/gtm/shared/multilabel/src')),
  MetaData: dynamic(() => import('@sitecore/components/system/MetaData/MetaData')),
  MetaTags: dynamic(() => import('@sitecore/components/system/MetaTags/MetaTags')),
  StructuredDataSchema: dynamic(() => import('@sitecore/components/system/StructuredDataSchema/StructuredDataSchema')),
  Unsubscribe: dynamic(() => import('@apps/unsubscribe/shared/multilabel/src/Unsubscribe')),

  // Wrapper components
  Article: dynamic(() => import('@sitecore/components/content/Article/Article')),
  Carousel: dynamic(() => import('@sitecore/components/content/Carousel/Carousel')),
  Centered: dynamic(() => import('@sitecore/components/content/Centered/Centered')),
  LineUp: dynamic(() => import('@sitecore/components/content/LineUp/LineUp')),
  NavigationView: dynamic(() => import('@sitecore/components/content/NavigationView/NavigationView')),
  Section: dynamic(() => import('@sitecore/components/content/Section/Section')),
  SplitView: dynamic(() => import('@sitecore/components/content/SplitView/SplitView')),

  // Wrappable components
  ArticleCard: dynamic(() => import('@sitecore/components/content/ArticleCard/ArticleCard')),
  ArticleContentCard: dynamic(() => import('@sitecore/components/content/ArticleContentCard/ArticleContentCard')),
  ContentCard: dynamic(() => import('@sitecore/components/content/ContentCard/ContentCard')),
  ProductCard: dynamic(() => import('@sitecore/components/content/ProductCard/ProductCard')),
  StickyNav: dynamic(() => import('@sitecore/components/content/StickyNav/StickyNav')),
  Table: dynamic(() => import('@sitecore/components/content/Table/Table')),
  TitleTextCTA: dynamic(() => import('@sitecore/components/content/TitleTextCTA/TitleTextCTA')),
  PersonalizedTitleTextCTA: dynamic(
    () => import('@sitecore/components/content/PersonalizedTitleTextCTA/PersonalizedTitleTextCTA'),
  ),
  Checklist: dynamic(() => import('@sitecore/components/content/Checklist/Checklist')),
  USPItem: dynamic(() => import('@sitecore/components/content/USPItem/USPItem')),
  Video: dynamic(() => import('@sitecore/components/content/Video/Video')),

  // MeterReadings
  'meterreadings-flow': dynamic(() => import('@apps/meterreadings/nl/multilabel/src/MeterReadingsFlow')),
  RegistrationAccountCreate: dynamic(
    () => import('@apps/accessmanagement/nl/multilabel/src/components/RegistrationFlow/RegistrationFlow'),
  ),
  RegistrationAccountReset: dynamic(
    () => import('@apps/accessmanagement/nl/multilabel/src/components/RegistrationFlow/RegistrationFlow'),
  ),
  RegistrationFooter: dynamic(
    () => import('@apps/accessmanagement/nl/multilabel/src/components/RegistrationFooter/RegistrationFooter'),
  ),
  RegistrationHeader: dynamic(
    () => import('@apps/accessmanagement/nl/multilabel/src/components/RegistrationHeader/RegistrationHeader'),
  ),

  //Forms
  GenericForm: dynamic(() => import('@sitecore/components/forms/GenericForm/GenericForm')),
  GenericFormAddressField: dynamic(
    () => import('@sitecore/components/forms/GenericForm/Components/GenericFormAddress/GenericFormAddressField'),
  ),
  GenericFormNameField: dynamic(
    () => import('@sitecore/components/forms/GenericForm/Components/GenericFormNameField/GenericFormNameField'),
  ),
  GenericFormParagraph: dynamic(
    () => import('@sitecore/components/forms/GenericForm/Components/GenericFormParagraph/GenericFormParagraph'),
  ),
  GenericFormSalutationField: dynamic(
    () => import('@sitecore/components/forms/GenericForm/Components/GenericFormSalutation/GenericFormSalutation'),
  ),
  GenericFormRadioGroup: dynamic(
    () => import('@sitecore/components/forms/GenericForm/Components/GenericFormRadioGroup/GenericFormRadioGroup'),
  ),
  GenericFormCheckboxField: dynamic(
    () => import('@sitecore/components/forms/GenericForm/Components/GenericFormCheckboxField/GenericFormCheckboxField'),
  ),
  GenericFormCheckboxGroup: dynamic(
    () => import('@sitecore/components/forms/GenericForm/Components/GenericFormCheckboxGroup/GenericFormCheckboxGroup'),
  ),
  GenericFormDateField: dynamic(
    () => import('@sitecore/components/forms/GenericForm/Components/GenericFormDateField/GenericFormDateField'),
  ),
  GenericFormEmailInputField: dynamic(
    () =>
      import('@sitecore/components/forms/GenericForm/Components/GenericFormEmailInputField/GenericFormEmailInputField'),
  ),
  GenericFormNumberInputField: dynamic(
    () =>
      import(
        '@sitecore/components/forms/GenericForm/Components/GenericFormNumberInputField/GenericFormNumberInputField'
      ),
  ),
  GenericFormPhoneNumberInputField: dynamic(
    () =>
      import(
        '@sitecore/components/forms/GenericForm/Components/GenericFormPhoneNumberInputField/GenericFormPhoneNumberInputField'
      ),
  ),
  GenericFormTextAreaInputField: dynamic(
    () =>
      import(
        '@sitecore/components/forms/GenericForm/Components/GenericFormTextAreaInputField/GenericFormTextAreaInputField'
      ),
  ),
  GenericFormInputSelect: dynamic(
    () => import('@sitecore/components/forms/GenericForm/Components/GenericFormInputSelect/GenericFormInputSelect'),
  ),
  GenericFormDivider: dynamic(
    () => import('@sitecore/components/forms/GenericForm/Components/GenericFormDivider/GenericFormDivider'),
  ),
  GenericFormInputField: dynamic(
    () => import('@sitecore/components/forms/GenericForm/Components/GenericFormInputField/GenericFormInputField'),
  ),
  ContactForm: dynamic(() => import('@sitecore/components/forms/ContactForm/ContactForm')),
  DeceasedForm: dynamic(() => import('@sitecore/components/forms/DeceasedForm/DeceasedForm')),
};
