// IMPORTANT: this file was generated using SVGR, please do not edit directly. Refer to the icon README.md for more info.
// eslint-disable-next-line import/order
import React, { FC } from 'react'; // eslint-disable-next-line @nx/enforce-module-boundaries
import { Icon, IconProps } from '@sparky/iconWrapper';
const SvgWarningIcon: FC<IconProps> = ({ color, size, verticalAlign }) => (
  <Icon color={color} size={size} verticalAlign={verticalAlign}>
    <path d="M4.47 21h15.06c1.54 0 2.5-1.67 1.73-3L13.73 4.99c-.77-1.33-2.69-1.33-3.46 0L2.74 18c-.77 1.33.19 3 1.73 3ZM12 14c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1s1 .45 1 1v2c0 .55-.45 1-1 1Zm1 4h-2v-2h2v2Z" />
  </Icon>
);
export default SvgWarningIcon;
SvgWarningIcon.displayName = 'SvgWarningIcon';
